(function( $ ) {

	class EditForm extends React.Component {
		render(){
			return (
				<form>
					<input ref="invision"
						defaultValue={this.props.initialInvision}
						className="field" type="url" placeholder="Invision URL" />
					<span className="field">mockup.ninja/<input
						ref="mockup"
						defaultValue={this.props.initialMockup}
						type="url" placeholder="mockup" />
					</span>
				</form>
			)
		}
	}

	class Mockup extends React.Component {
		constructor( props ){
			super( props )
			this.state = { edit: false }
		}
		delete(){
			this.props.deleteMockup( this.props.index )
		}
		save(){
			var mockup = {
				invision: this.refs.editForm.refs.invision.value,
				mockup: this.refs.editForm.refs.mockup.value
			}
			this.props.replaceMockup( this.props.index, mockup )
			this.setState( {edit: false})
		}
		render(){
			var mockupURL = this.props.mockup.mockup
			var invisionURL = this.props.mockup.invision
			return (
				<div className="mockup">
					<header>
						<a className="link" href={invisionURL} target="_blank" >
							<span className="hostname">mockup.ninja</span>/<b>{mockupURL}</b>
						</a>
						<div className="buttons">
							<button onClick={ copyToClipboard.bind( this, window.location.origin + '/' + mockupURL ) }>Copy Link</button>
							<button onClick={ ()=>{this.setState( {edit:true}) } }>Edit</button>
							<button onClick={ this.delete.bind(this) } >Delete</button>
						</div>
					</header>
					{ this.state.edit &&
						<EditForm ref="editForm" initialInvision={invisionURL} initialMockup={mockupURL} />
					}
					{ this.state.edit &&
						<footer>
							<button onClick={ ()=>{this.setState( {edit:false} )} } className="cancel">Cancel</button>
							<button onClick={this.save.bind(this)}>Done Editing</button>
						</footer>
					}
				</div>
			)
		}
	}

	class MockupNinja extends React.Component {
		constructor( props ){
			super( props )
			this.state = { mockups: this.props.initialMockups }
		}
		componentDidUpdate(){
			$.post( '/', {'mockups': JSON.stringify(this.state.mockups) }).done(function( r ){console.log(r)})
		}
		addMockup( mockup, invision ){
			var mockups = this.state.mockups
			mockups.push( { mockup, invision } )
			this.setState( { mockups: this.sortMockups(mockups) } )
		}
		replaceMockup( index, mockup ){
			var mockups = this.state.mockups
			mockups.splice( index, 1, mockup )
			this.setState( {mockups: this.sortMockups(mockups) })
		}
		deleteMockup( index ){
			var mockups = this.state.mockups
			mockups.splice( index, 1 )
			this.setState( mockups: mockups )
		}
		mockups(){
			if( this.state.mockups.length > 0 )
				return this.state.mockups.map( function( m, i ){
					return(
						<Mockup key={Math.random()} index={i} mockup={m}
							replaceMockup={this.replaceMockup.bind(this)}
							deleteMockup={this.deleteMockup.bind(this)}
						/>
					)
				}.bind(this))
			else
				return <div className="loading">no mockups</div>
		}
		sortMockups( mockups ){
			var mockups = this.state.mockups
			mockups.sort( function( m, m2 ){
				return m.mockup.localeCompare( m2.mockup )
			})
			return mockups
		}
		render(){
			return(
				<div>
					<AddMockup addMockup={this.addMockup.bind(this)} />
					{this.mockups()}
				</div>
			)
		}
	}

	class AddMockup extends React.Component {
		addMockup( e ){
			e.preventDefault()
			var form = this.refs.edit
			this.props.addMockup( form.refs.mockup.value, form.refs.invision.value )
			form.refs.mockup.value = ''
			form.refs.invision.value = ''
			form.refs.invision.focus()
		}
		render(){
			return (
				<div className="mockup add-mockup">
					<h2>Add Mockup</h2>
					<EditForm ref="edit" />
					<footer>
						<button onClick={this.addMockup.bind(this)}>Add Mockup</button>
					</footer>
				</div>
			)
		}
	}


	ReactDOM.render(
		<MockupNinja initialMockups={ MOCKUPS }/>,
		document.getElementById('app')
	)
})( jQuery );
